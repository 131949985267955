<template>
  <div class="main-body">
    <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-divider orientation="left">版权设置</a-divider>
      <a-form-item label="企业名称">
        <a-input v-model:value="formState.company_name" placeholder="请输入企业名称" />
      </a-form-item>
      <a-form-item label="网站ICP备案号">
        <a-input v-model:value="formState.icp" placeholder="请输入网站ICP备案号" />
      </a-form-item>
      <a-form-item label="ICP备案查询地址">
        <a-input v-model:value="formState.icp_link" placeholder="请输入ICP备案查询地址" />
      </a-form-item>
      <a-form-item label="网站公安备案">
        <a-input v-model:value="formState.security_code" placeholder="请输入网站公安备案" />
      </a-form-item>
      <a-form-item label="网站公安链接">
        <a-input v-model:value="formState.security_link" placeholder="请输入网站公安链接" />
      </a-form-item>
      <a-form-item label="市场监督管理局链接">
        <a-input v-model:value="formState.supervision" placeholder="请输入市场监督管理局链接" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 7, offset: 3 }">
        <a-button type="primary" @click="onSubmit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, onMounted } from "vue";
import * as Api from "@/views/setting/api";
import { message } from "ant-design-vue";

export default defineComponent({
  setup() {
    const formState = reactive({
      company_name: "",
      icp: "",
      security_code: "",
      security_link: "",
      supervision: "",
    });

    const onSubmit = () => {
      Api.save_setting(formState).then((res) => {
        message.success(res.message);
      });
    };

    const getInitSetting = () => {
      Api.get_global_setting().then((res) => {
        formState.company_name = res.data.company_name;
        formState.icp = res.data.icp;
        formState.security_code = res.data.security_code;
        formState.security_link = res.data.security_link;
        formState.supervision = res.data.supervision;
      });
    };

    onMounted(() => {
      getInitSetting();
    });

    return {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 7,
      },
      formState,
      onSubmit,
    };
  },
});
</script>